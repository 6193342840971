<template>
  <v-card class="docs">
    <v-card-title>
      <div class="text-h5 text-center">Список документов</div>
    </v-card-title>

    <div class="popup-filter">
      <v-autocomplete
          dense
          v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
          v-model="cznFilter"
          label="Фильтр по ЦЗН"
          :items="departments"
          item-text="name"
          return-object
          @change="$forceUpdate()"
          clearable
      ></v-autocomplete>
    </div>

    <div class="popup-filter">
      <v-autocomplete
          dense
          v-model="categoryFilter"
          label="Фильтр по разделам"
          :items="getDocumentCategories()"
          @change="$forceUpdate()"
          clearable
      ></v-autocomplete>
    </div>

    <div class="docs-table">
      <table>
        <tr>
          <th class="doc-title">Документ</th>
          <th class="doc-czn">ЦЗН</th>
          <th class="doc-date">Дата загрузки</th>
        </tr>
        <template v-for="category in getDocumentFilteredCategories()">
          <tr  :key="'cat_tr1_' + category">
            <th colspan="3">{{category}}</th>
          </tr>
          <tr
              v-for="document in getDocumentsByCategory(category)"
              :key="'doc'+category+'_'+document.file.id">
            <td>
              <a class="docs-attach" @click="downloadFile(document.file.id, document.file.name)">{{truncateStr(document.file.name, 30)}}</a>
            </td>
            <td>
              {{ document.department == null ? 'Все' : document.department.name }}
            </td>
            <td>{{ document.file.createdDateTime | dateFormat }}</td>
          </tr>
        </template>


        <tr v-if="getUncategorizedDocumentns().length > 0">
          <th colspan="3">Без раздела</th>
        </tr>
          <tr
              v-for="document in getUncategorizedDocumentns()"
              :key="'doc_'+document.file.id">
            <td>
              <a class="docs-attach" @click="downloadFile(document.file.id, document.file.name)">{{truncateStr(document.file.name, 30)}}</a>
            </td>
            <td>
              {{ document.department == null ? 'Все' : document.department.name }}
            </td>
            <td>{{ document.file.createdDateTime | dateFormat }}</td>
          </tr>
      </table>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Закрыть
      </v-btn>
      <v-btn
          v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
          class="btn btn-primary"
          @click="$emit('edit')"
      >
        Редактировать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/modules/api";

export default {
  name: 'PassportInfoDocsDialog',
  props: ['region', 'departments', 'section'],

  data: () => ({
    documents: [],
    categoryFilter: null,
    cznFilter: null
  }),
  methods: {
    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    getDocumentCategories(){
      const categories = []
      for(let doc of this.documents){
        if(!doc.deleted &&
            (this.cznFilter==null || this.cznFilter.id === doc.department.id) &&
            doc.category &&
            !categories.includes(doc.category)){
          categories.push(doc.category)
        }
      }
      return categories
    },

    getDocumentFilteredCategories(){
      if(this.categoryFilter) return [this.categoryFilter]
      else return this.getDocumentCategories()
    },

    getDocumentsByCategory(cat){
      let docs = []
      for(let doc of this.documents){
        if(doc.category === cat
            && !doc.deleted
            && (this.cznFilter==null || this.cznFilter.id === doc.department.id) ) {
          docs.push(doc)
        }
      }
      return docs
    },

    getUncategorizedDocumentns(){
      if(this.categoryFilter) return []
      let docs = []
      for(let doc of this.documents){
        if(doc.category == null && !doc.deleted) {
          docs.push(doc)
        }
      }
      return docs
    },

    async downloadFile(fileId, fileName) {
      let req = await api.download("/uuidfs/" + fileId, {});
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    filterCzns(){
      this.documents = this.documents.filter(e=>{
        return e.department == null
            || e.department.id === this.$user.department.id
      })
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    },
  },
  async beforeMount() {
    let req = await api.get('passport/getDocuments/' + this.region.code)
    if(req.ok){
      this.documents = req.payload
      this.documents=this.documents.filter(e=>e.section===this.section)
      if(this.$user.role==='ROLE_CZN_MANAGER' || this.$user.role==='ROLE_CZN_TERRITORIAL'){
        this.filterCzns()
      }
    } else {
      alert('Ошибка загрузки данных')
    }
  }
}
</script>


