<template>
  <v-card class="docs">
    <v-card-title>
      <div class="text-h5 text-center">Редактирование документов</div>
    </v-card-title>

    <div class="docs-table">
      <table>
        <tr>
          <th class="doc-title">Документ</th>
          <th class="doc-czn">Раздел</th>
          <th class="doc-czn">ЦЗН</th>
          <th class="doc-date">Дата загрузки</th>
          <th class="doc-del"></th>
        </tr>
        <template v-for="category in getDocumentCategories()">
          <tr  :key="'cat_tr1_' + category">
            <th colspan="5">{{category}}</th>
          </tr>
          <tr
              v-for="document in getDocumentsByCategory(category)"
              :key="'doc'+category+'_'+document.file.id">
            <td>
              <a class="docs-attach" @click="downloadFile(document.file.id, document.file.name)">{{truncateStr(document.file.name, 30)}}</a>
            </td>
            <td>
              <v-autocomplete
                  dense
                  :items="categories"
                  v-model="document.category"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                  dense
                  :items="departments"
                  item-text="name"
                  return-object
                  v-model="document.department"
              ></v-autocomplete>
            </td>
            <td>{{ document.file.createdDateTime | dateFormat }}</td>
            <td class="doc-del" style="cursor:pointer" @click="deleteDocument(document)"></td>
          </tr>
        </template>

        <tr
            v-for="document in getUncategorizedDocumentns()"
            :key="'docnocat_'+document.file.id">
          <td>
            <a class="docs-attach" @click="downloadFile(document.file.id, document.file.name)">{{truncateStr(document.file.name, 30)}}</a>
          </td>
          <td>
            <v-autocomplete
                dense
                :items="categories"
                v-model="document.category"
            ></v-autocomplete>
          </td>
          <td>
            <v-autocomplete
                dense
                :items="departments"
                item-text="name"
                return-object
                v-model="document.department"
            ></v-autocomplete>
          </td>
          <td>{{ document.file.createdDateTime | dateFormat }}</td>
          <td class="doc-del" style="cursor:pointer" @click="deleteDocument(document)"></td>
        </tr>

      </table>
    </div>
    <div class="doc-add">
      <a href="#" @click.prevent="$refs['fileupload'].click()">Добавить документ</a>
      <input type="file" ref="fileupload" style="display:none" @change="addDocument($event.target.files[0])">

    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Закрыть
      </v-btn>
      <v-btn
          class="btn btn-primary"
          @click="submit()"
      >
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/modules/api";

export default {
  name: 'PassportInfoDocsUploadDialog',
  props: ['region', 'departments', 'categories', 'section'],
  data: () => ({
    documents: []
  }),
  methods: {
    async uploadFile(file){
      if (file.size > 52428800) {
        alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
        return null
      }
      let formData = new FormData();
      formData.append('file', file);
      let req = await api.postFile('/uuidfs/', {}, formData, 'POST');
      console.log('req', req)
      if (req.ok) {
        console.log('upload ok');
        return req.payload
      } else {
        alert('Ошибка загрузки файла: ' + req.error)
        console.log('upload error', req.error)
      }
    },

    async addDocument(file){
      const uploadedFile = await this.uploadFile(file)
      this.documents.push({
        id: null,
        file: uploadedFile,
        regionCode: this.region.code,
        department: null,
        category: null,
        section: this.section
      })
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    deleteDocument(document){
      document.deleted = true
      this.$forceUpdate()
      // for(let i = 0; i < this.documents.length; i++){
      //   if(document === this.documents[i]){
      //     this.documents.splice(i, 1)
      //     this.$forceUpdate()
      //   }
      // }
    },

    getDocumentCategories(){
      const categories = []
      for(let doc of this.documents){
        if(!doc.deleted && doc.category && !categories.includes(doc.category)){
          categories.push(doc.category)
        }
      }
      return categories
    },

    getDocumentsByCategory(cat){
      let docs = []
      for(let doc of this.documents){
        if(doc.category === cat && !doc.deleted) {
          docs.push(doc)
        }
      }
      return docs
    },

    getUncategorizedDocumentns(){
      let docs = []
      for(let doc of this.documents){
        if(doc.category == null && !doc.deleted) {
          docs.push(doc)
        }
      }
      return docs
    },

    async downloadFile(fileId, fileName) {
      let req = await api.download("/uuidfs/" + fileId, {});
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    async submit(){
      console.log('submit', this.documents)
      let req = await api.postJson('passport/addDocuments/' + this.region.code, this.documents)
      if(req.ok){
        this.$emit('close')
      } else {
        alert('Ошибка сохранения данных')
      }
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    let req = await api.get('passport/getDocuments/' + this.region.code)
    if(req.ok){
      this.documents = req.payload
      this.documents=this.documents.filter(e=>e.section===this.section)
      console.log(this.documents)
    } else {
      alert('Ошибка загрузки данных')
    }
  }
}
</script>


